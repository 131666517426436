import React from 'react'
import styled from "styled-components"
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion"

const SectionWrapper = styled.section`
@media screen and (min-width: 670px){
padding-bottom: 80px;
}
`

const SectionInner = styled.div`
@media screen and (max-width: 1920px){
    max-width: 1330px;
}
width: 94%;
margin-right: auto;
margin-left: auto;

`

const SectionTitle = styled.h3`
@media screen and (max-width: 670px){
    margin-bottom: 20px;
}
@media screen and (max-width: 670px){
    
    font-size: 1.75rem;
}
@media screen and (max-width: 1024px){
    
    font-size: 2.625rem;
}

display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
color: #C24C92;

&:after{
    content: "";
    min-width: 50px;
    height: 2px;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    justify-self: flex-end;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: auto;
    background: #C24C92;
}

@media screen and (max-width: 670px){
    &:after{
        max-width: 48.125%;
    }
}

@media screen and (max-width: 1920px) and (min-width: 671px){
    margin-bottom: 30px;
}

@media screen and (min-width: 1025px){
    
    font-size: 3.25rem;
}
`

const SectiontitleSpan = styled.span`
margin-right: 30px;

`
const PostCat = styled.ul`
list-style: none;
@media screen and (min-width: 769px){
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
`

const PostCatItem = styled.li`
@media screen and (max-width: 670px){
    &:nth-child(n+2){
    margin-top: 30px;
    }
}

@media screen and (max-width: 670px){
    
    font-size: 1rem;
    line-height: 180%;
}
@media screen and (min-width: 769px){
    width: 30.8333333333%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

@media screen and (min-width: 1025px){
    
    font-size: 1.125rem;
}

@media screen and (min-width: 769px){
    &:nth-child(2) {
        margin-left: 3.75%;
    }

    &:nth-child(3) {
        margin-left: 3.75%;
    }
}
`
const PostCatItemTitleWrapper = styled.div`
@media screen and (max-width: 670px){
    margin-bottom: 20px;
}

@media screen and (min-width: 671px){
    margin-bottom: 30px;
}

display: -webkit-flex;
display: flex;
-webkit-flex-wrap: nowrap;
flex-wrap: nowrap;
-webkit-align-items: center;
align-items: center;
`

const PostCatItemTitle = styled.h2`
margin-right: 20px;

@media screen and (max-width: 670px){
    
    font-size: 1.125rem;
}
@media screen and (max-width: 1024px){
    
    font-size: 1.375rem;
}

.post-cats__item-title h6, .post-cats__item-title .authors__items--std .authors__item-name, .authors__items--std .post-cats__item-title .authors__item-name {
    margin-right: 20px;
}

@media screen and (min-width: 1025px){
    
    font-size: 1.625rem;
}
`

const PostCatItemLink = styled(Link)`
-webkit-flex-shrink: 0;
flex-shrink: 0;
margin-left: auto;
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
letter-spacing: .1em;
`

const PostCatItemContent = styled.div`

`
const PostCatItemImgBox = styled.div`

`

const PostCatItemImgLink = styled(Link)`
position: relative;
display: block;
overflow: hidden;
text-decoration: none;
color: #303C3C;
`

const PostCatItemImg = styled(GatsbyImage)`
width: 100%;
display: block;
max-width: 100%;
height: auto;
`

const PostCatItemImgTitle = styled.p`
@media screen and (max-width: 670px){
    margin-top: 15px;
}

@media screen and (max-width: 670px){
    
    font-size: 1rem;
    line-height: 180%;
}

@media screen and (max-width: 1920px) and (min-width: 671px){
    margin-top: 15px;
}

@media screen and (min-width: 1025px){
    
    font-size: 1.125rem;
}

@media screen and (max-width: 1920px) and (min-width: 671px){
    margin-top: 15px;
}

@media screen and (min-width: 1025px){
    
    font-size: 1.125rem;
}

text-align:center;
`

const PostCatItemImgTitleLink = styled(Link)`
text-decoration: none;
color: #303C3C;
text-align:center;
`

const PostItemList = styled.ul`
list-style: none;
`

const PostItemListPost = styled.li`
@media screen and (max-width: 670px){
    
    font-size: 1rem;
    line-height: 180%;
}

list-style-type: none; 
`

const PostItemListPostTitle = styled.p`
@media screen and (max-width: 670px){
    
    font-size: 1rem;
    line-height: 180%;
}

@media screen and (max-width: 1024px){
    
    font-size: 1rem;
    line-height: 180%;
}

`

const PostItemListPostLink = styled(Link)`
border-bottom: 1px solid rgba(48,60,60,0.15);
line-height: 180%;
text-decoration: none;
color: #303C3C;
display: block;
padding: 15px 0;

@media screen and (max-width: 670px){
    
    font-size: .75rem;
}
@media screen and (max-width: 1024px){
    
    font-size: .75rem;
}

@media screen and (min-width: 1025px){
    
    font-size: .875rem;
}
`


const Featured = ({data}) => {

    if (!data) return null;
    const posts = data.edges;
    
    const factsPosts = posts.filter(data => data.node.frontmatter.tags[0] === "crystal facts");
    const factsPostsTagUrl = factsPosts[0].node.frontmatter.tags[0].replace(/\s+/g, '-');
    const buyingPosts = posts.filter(data => data.node.frontmatter.tags[0] === "buying guides");
    const buyingPostsTagUrl = buyingPosts[0].node.frontmatter.tags[0].replace(/\s+/g, '-');
    const commonPosts = posts.filter(data => data.node.frontmatter.tags[0] === "common questions");
    const commonPostsTagUrl = commonPosts[0].node.frontmatter.tags[0].replace(/\s+/g, '-');

    return(
    <>
    <SectionWrapper>
        <SectionInner>
            <SectionTitle>
                <SectiontitleSpan>Featured</SectiontitleSpan>
            </SectionTitle>

            <PostCat>
            {factsPosts.length > 0 &&  <PostCatItem>
                    <PostCatItemTitleWrapper>
                        <PostCatItemTitle>{factsPosts[0].node.frontmatter.tags[0]}</PostCatItemTitle>
                        <PostCatItemLink to={`/${factsPostsTagUrl}`}>View all</PostCatItemLink>
                    </PostCatItemTitleWrapper>
                    
                    <PostCatItemContent>
                        <PostCatItemImgBox
                          as={motion.div}
                          whileHover ={{
                              scale : [1, 1.1],
                              zIndex:  99,
                              transition: {
                                  duration: .4
                              }
                              }}>
                            <PostCatItemImgLink to={`${factsPosts[0].node.slug}`}>
                                <PostCatItemImg
                                    image={factsPosts[0].node.frontmatter.featuredimage.childImageSharp.small}
                                    alt= {`featured image thumbnail for post ${factsPosts[0].node.frontmatter.title}`}
                                />
                            </PostCatItemImgLink>

                            <PostCatItemImgTitle>
                                <PostCatItemImgTitleLink to={`${factsPosts[0].node.slug}`}>{factsPosts[0].node.frontmatter.title}</PostCatItemImgTitleLink>
                            </PostCatItemImgTitle>
                        </PostCatItemImgBox>
                            <PostItemList >
                            {factsPosts.slice(1, 6).map(({ node: post }) => (
                                <PostItemListPost>
                                    <PostItemListPostTitle>
                                        <PostItemListPostLink to={`/${post.slug}`}>{post.frontmatter.title}</PostItemListPostLink>                                    </PostItemListPostTitle>
                                </PostItemListPost>
                             ))} 
                            </PostItemList>

                    </PostCatItemContent>
                   
                </PostCatItem>
            }   
             
            {buyingPosts.length > 0 &&    <PostCatItem>
                    <PostCatItemTitleWrapper>
                        <PostCatItemTitle>{buyingPosts[0].node.frontmatter.tags[0]}</PostCatItemTitle>
                        <PostCatItemLink to={`/${buyingPostsTagUrl}`}>View all</PostCatItemLink>
                    </PostCatItemTitleWrapper>
                    
                    <PostCatItemContent>
                        <PostCatItemImgBox>
                            <PostCatItemImgLink to={`${buyingPosts[0].node.slug}`}>
                                <PostCatItemImg
                                    image={buyingPosts[0].node.frontmatter.featuredimage.childImageSharp.small}
                                    alt= {`featured image thumbnail for post ${buyingPosts[0].node.frontmatter.title}`}
                                />
                            </PostCatItemImgLink>

                            <PostCatItemImgTitle>
                                <PostCatItemImgTitleLink to={`${buyingPosts[0].node.slug}`}>{buyingPosts[0].node.frontmatter.title}</PostCatItemImgTitleLink>
                            </PostCatItemImgTitle>
                        </PostCatItemImgBox>
                            <PostItemList >
                            {buyingPosts.slice(1, 6).map(({ node: post }) => (
                                <PostItemListPost>
                                    <PostItemListPostTitle>
                                        <PostItemListPostLink to={`/${post.slug}`}>{post.frontmatter.title}</PostItemListPostLink>                                    </PostItemListPostTitle>
                                </PostItemListPost>
                            ))} 
                            </PostItemList>
                    </PostCatItemContent>
                   
                </PostCatItem>
            }


            {commonPosts.length > 0 &&   
            <PostCatItem>
                    <PostCatItemTitleWrapper>
                        <PostCatItemTitle>{commonPosts[0].node.frontmatter.tags[0]}</PostCatItemTitle>
                        <PostCatItemLink to={`/${commonPostsTagUrl}`}>View all</PostCatItemLink>
                    </PostCatItemTitleWrapper>
                    
                    <PostCatItemContent>
                        <PostCatItemImgBox>
                            <PostCatItemImgLink to={`/${commonPosts[0].node.slug}`}>
                                <PostCatItemImg
                                    image={commonPosts[0].node.frontmatter.featuredimage.childImageSharp.small}
                                    alt= {`featured image thumbnail for post ${commonPosts[0].node.frontmatter.title}`}
                                />
                            </PostCatItemImgLink>

                            <PostCatItemImgTitle>
                                <PostCatItemImgTitleLink to={`/${commonPosts[0].node.slug}`}>{commonPosts[0].node.frontmatter.title}</PostCatItemImgTitleLink>
                            </PostCatItemImgTitle>
                        </PostCatItemImgBox>
                         <PostItemList>
                            {commonPosts.slice(1, 5).map(({ node: post }) => (
                                <PostItemListPost>
                                    <PostItemListPostTitle>
                                        <PostItemListPostLink to={`/${post.slug}`}>{post.frontmatter.title}</PostItemListPostLink>                                    </PostItemListPostTitle>
                                </PostItemListPost>
                            ))} 
                            </PostItemList>
                    </PostCatItemContent>
                   
            </PostCatItem>
            }
            </PostCat>
                         
        </SectionInner>
    </SectionWrapper>

    </>
    )
  }



export default Featured