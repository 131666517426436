import * as React from "react"
import {graphql} from 'gatsby'
import Layout from "../components2/Layout";
import Hero from "../components2/Hero";
import MostRecent from "../components2/MostRecent";
import GoodReads from "../components2/GoodReads";
import Seo from "../components2/Seo";
import Featured from "../components2/Featured";
// markup


const IndexPage = ({data}) => {
  return (
    <Layout>  
      <Seo title="Home" defer={false}/>
      <Hero data={data.hero.edges[0].node}/>
      <MostRecent data={data.posts}/>
      <Featured data={data.posts}/>
      <GoodReads data={data.goodReads}/>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
query {
  posts:  allMdx(filter: {frontmatter: {templateKey: {eq: "blog-post"}}} sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              tags
              featuredimage {
                childImageSharp {
                  large: 
                      gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                      sizes: "(min-width: 769px) 400px, 50vw"
                    )
                  small: 
                      gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                      sizes: "(max-width: 768px) 200px, 25vw"
                    )
                  }
              }
            }
          }
        }
      }
      goodReads:  allMdx(filter: {frontmatter: {article: {eq: false}, templateKey: {eq: "blog-post"}}} sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              tags
              featuredimage {
                childImageSharp {
                  large: 
                      gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                      sizes: "(min-width: 769px) 400px, 50vw"
                    )
                  small: 
                      gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                      sizes: "(max-width: 768px) 200px, 25vw"
                    )
                  }
              }
            }
          }
        }
      }
  hero: allMdx(filter: {frontmatter: {templateKey: {eq: "hero-page"}}}) {
    edges {
      node {
        id
        slug
        frontmatter {
          title
          heroImage {
            childImageSharp {
                gatsbyImageData(
                placeholder: DOMINANT_COLOR
                layout: FULL_WIDTH
                sizes: "(min-width: 1000px) 500px, 50vw"
              )
              }
          }
        }
      }
    }
  }
}
`;