import React from 'react'
import styled from "styled-components"
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion"

const SectionWrapper = styled.section`
padding-top: 0;

@media screen and (max-width: 670px){
    padding-bottom: 50px;
}

@media screen and (max-width: 1920px) and (min-width: 671px){
    padding-top: 0px;
    padding-bottom: 80px;
}
`

const InnerSectionWrapper = styled.div`
@media screen and (max-width: 1920px){
    max-width: 1330px;
}

width: 94%;
margin-right: auto;
margin-left: auto;
`

const SectionTitle = styled.h3`
@media screen and (max-width: 670px){
    margin-bottom: 20px;
}

media screen and (min-width: 1025px){
    
    font-size: 3.25rem;
}

@media screen and (max-width: 1920px) and (min-width: 671px){
    margin-bottom: 30px;
}

@media screen and (min-width: 1025px){
    
    font-size: 3.25rem;
}

@media screen and (max-width: 670px){
    
    font-size: 1.75rem;
}

display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
color: #C24C92;

&:after{
    content: "";
    min-width: 50px;
    height: 2px;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    justify-self: flex-end;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: auto;
    background: #C24C92;
}

@media screen and (max-width: 670px){
    &:after{
        max-width: 48.125%;
    }
}

`

const SectionTitleSpan = styled.span`
margin-right: 30px;
`

const ArticleGrid = styled.div`
@media screen and (min-width: 769px){
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items:center;
}
`

const LargePostPreview = styled.article`
@media screen and (min-width: 769px){
    width: 48.125%;
}

@media screen and (min-width: 769px){
    &:not(:first-child) {
        margin-top: 3.75%;
    }
}

@media screen and (min-width: 769px){
    &:not(:first-child){ 
        margin-left: 3.75%;
    }
}
`

const LargePostPreviewLinkWrapper = styled(Link)`
position: relative;
display: block;
overflow: hidden;
text-decoration: none;
color: #303C3C;
`

const LargePostPreviewPicture = styled.div`
max-width: 100%;
height: auto;
`

const LargePostPreviewPictureImg = styled(GatsbyImage)`
width: 100%;
display: block;
display: block;
max-width: 100%;
height: auto;
`

const LargePostPreviewTextWrapper = styled.div`
@media screen and (max-width: 670px){
    margin: -10px 10px 0px 10px;
    padding: 20px;
}

padding: 20px 40px 30px;
@media screen and (min-width: 1025px){
    padding: 20px 50px 40px;
}

@media screen and (min-width: 671px){
    margin: -20px 20px 0 20px;
}


position: relative;
background: #fff;
z-index: 1;
text-align: center;
`

const LargePostPreviewTextTitle = styled.span`
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
letter-spacing: .1em;

@media screen and (max-width: 670px){

    
    font-size: .75rem;
}

@media screen and (max-width: 1024px){
    
    font-size: .75rem;
}

@media screen and (min-width: 1025px){
    
    font-size: .875rem;
}
`

const LargePostPreviewText = styled.p`
margin-top: 25px;

@media screen and (max-width: 670px){
    margin-bottom: 10px;
}
@media screen and (max-width: 670px){
    
    font-size: 1.125rem;
}
@media screen and (max-width: 1024px){
    
    font-size: 1.375rem;
}
@media screen and (max-width: 670px){
    
    font-size: 1rem;
    line-height: 180%;
}

@media screen and (min-width: 1025px){
    
    font-size: 1.625rem;
}
`

const LargePostPreviewLink = styled(Link)`
text-decoration: none;
color: #303C3C;
`

/* SMALL POSTS */

const PostPreview2ColumnRight = styled.div`
@media screen and (max-width: 768px){
    margin-top: 3.75%;
}

@media screen and (min-width: 769px){
    margin-left: 3.75%;
}

@media screen and (min-width: 769px){
    width: 48.125%;
}

display: -webkit-flex;
display: flex;
-webkit-flex-wrap: wrap;
flex-wrap: wrap;
`

const PostPreview2ColumnLeft = styled.div`
@media screen and (max-width: 768px){
    margin-top: 3.75%;
}

@media screen and (min-width: 769px){
    margin-top: 3.75%;
}

@media screen and (min-width: 769px){
    width: 48.125%;
}

display: -webkit-flex;
display: flex;
-webkit-flex-wrap: wrap;
flex-wrap: wrap;
`

const PostPreview4Column = styled.div`
@media screen and (max-width: 768px){
    margin-top: 3.75%;
}

@media screen and (max-width: 768px){
    width: 100%;
}

@media screen and (min-width: 769px){
    margin-top: 3.75%;
}

@media screen and (min-width: 769px){
    width: 100%;
}

display: -webkit-flex;
display: flex;
-webkit-flex-wrap: wrap;
flex-wrap: wrap;

`

const SmallPostPreview = styled.article`
@media screen and (max-width: 1410px){
    width: calc(50% - (1.7625vw) - 1px);
}

 @media screen and (max-width: 1410px){
    &:nth-child(even){
        margin-left: 3.525vw;
    }
}

@media screen and (max-width: 1410px){
    &:nth-child(n+3) {
        margin-top: 3.525vw;
    }
}

@media screen and (min-width: 1411px){
    &:nth-child(even) {
        margin-left: 49.875px;
    }
}

@media screen and (min-width: 1411px){
    width: calc(50% - (24.9375px));
}
`

const SmallPostPreview4Col = styled.article`
@media screen and (max-width: 1410px){
    width: calc(50% - (1.7625vw) - 1px);
}

 @media screen and (max-width: 1410px){
    &:nth-child(even){
        margin-left: 3.525vw;
    }
}

@media screen and (min-width: 769px){
    width: 22.1875%;
}

@media screen and (min-width: 769px){
    &:nth-child(n+2) {
    margin-left: 3.75%;
}
`

const SmallPostPreviewLinkWrapper = styled(Link)`
position: relative;
display: block;
overflow: hidden;
text-decoration: none;
color: #303C3C;
`

const SmallPostPreviewPicture = styled.div`
max-width: 100%;
height: auto;
`

const SmallPostPreviewPictureImg = styled(GatsbyImage)`
width: 100%;
display: block;
max-width: 100%;
height: auto;
`

const SmallPostPreviewTextWrapper = styled.div`
@media screen and (max-width: 670px){
    padding-top: 10px;
}

@media screen and (min-width: 671px){
    padding: 20px 10px 0;
}

text-align: center;

`

const SmallPostPreviewTextTitle = styled.span`
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
letter-spacing: .1em;

@media screen and (max-width: 670px){
    
    font-size: .75rem;
}

@media screen and (max-width: 1024px){
    
    font-size: .75rem;
}
`

const SmallPostPreviewText = styled.p`
line-height: 180%;

@media screen and (max-width: 670px){
    margin-bottom: 10px;
}

@media screen and (min-width: 671px){
    margin-top: 20px;
}

@media screen and (max-width: 670px){
    
    font-size: 1rem;
}
@media screen and (max-width: 1024px){
    
    font-size: 1rem;
}
`

const SmallPostPreviewLink = styled(Link)`
text-decoration: none;
color: #303C3C;
`
const AllArticlesLink = styled(Link)`
padding: 14px 30px;
box-sizing: border-box;
@media screen and (max-width: 670px){
    margin-top: 30px;
}

@media screen and (min-width: 1025px){
    
    font-size: 1.625rem;
    padding: 22px 36px;
margin-top: 5vh;
}

@media screen and (max-width: 670px){
    
    font-size: 1rem;
    width:100%;
}


&:after{
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    top: 4px;
    left: 4px;content: "";
    position: absolute;
    border: 1px solid #fff1de;
}
width: 100%;
display: inline-block;
position: relative;
cursor: pointer;
border: none;
background: #C24C92;
text-align: center;
color: #fff1de;
text-decoration: none;

font-family: "Anton", "Impact", Impact;
font-weight: 400;
text-transform: uppercase;
line-height: 120%;


`



const MostRecent = ({data}) => {
    if (!data) return null;
    const posts = data.edges;
    return( <>
    <SectionWrapper>
    <InnerSectionWrapper>

        <SectionTitle>
            <SectionTitleSpan>Most recent</SectionTitleSpan>
        </SectionTitle>

        <ArticleGrid>

           {posts &&
            posts.slice(0, 1).map(({ node: post }) => (
                <LargePostPreview 
                key={post.id}>
                    <LargePostPreviewLinkWrapper to={`/${post.slug}`} >
                        <LargePostPreviewPicture
                         as={motion.div}
                         whileHover ={{
                             scale : [1, 1.1],
                             zIndex:  99,
                             transition: {
                                 duration: .4
                             }
                             }}>
                            <LargePostPreviewPictureImg
                                image={post.frontmatter.featuredimage.childImageSharp.large}
                                alt= {`featured image thumbnail for post ${post.frontmatter.title}`}
                                />
                        </LargePostPreviewPicture>
                    </LargePostPreviewLinkWrapper>
                
                    <LargePostPreviewTextWrapper>
                        <LargePostPreviewTextTitle>{post.frontmatter.tags[0]}</LargePostPreviewTextTitle>
                        <LargePostPreviewText>
                            <LargePostPreviewLink to={`/${post.slug}`}>{post.frontmatter.title}</LargePostPreviewLink>
                        </LargePostPreviewText>
                    </LargePostPreviewTextWrapper>
                    
                </LargePostPreview>
            ))}       
            <PostPreview2ColumnRight>
                {posts &&
                posts.slice(1, 5).map(({ node: post }) => (
                <SmallPostPreview
                key={post.id}>
                    <SmallPostPreviewLinkWrapper to={`/${post.slug}`}>
                        <SmallPostPreviewPicture
                         as={motion.div}
                         whileHover ={{
                             scale : [1, 1.1],
                             zIndex:  99,
                             transition: {
                                 duration: .4
                             }
                             }}>
                            <SmallPostPreviewPictureImg
                               image={post.frontmatter.featuredimage.childImageSharp.small}
                               alt= {`featured image thumbnail for post ${post.frontmatter.title}`}
                               />
                        </SmallPostPreviewPicture>
                    </SmallPostPreviewLinkWrapper>
                    <SmallPostPreviewTextWrapper>
                        <SmallPostPreviewTextTitle>{post.frontmatter.tags[0]}</SmallPostPreviewTextTitle>
                        <SmallPostPreviewText>
                            <SmallPostPreviewLink to={`/${post.slug}`}>{post.frontmatter.title}</SmallPostPreviewLink>
                        </SmallPostPreviewText>
                    </SmallPostPreviewTextWrapper>
                </SmallPostPreview>
                ))}
            </PostPreview2ColumnRight>

            <PostPreview4Column>
                {posts &&
                    posts.slice(5, 9).map(({ node: post }) => (
                    <SmallPostPreview4Col
                    key={post.id}>
                        <SmallPostPreviewLinkWrapper to={`/${post.slug}`}>
                            <SmallPostPreviewPicture
                             as={motion.div}
                             whileHover ={{
                                 scale : [1, 1.1],
                                 zIndex:  99,
                                 transition: {
                                     duration: .4
                                 }
                                 }}>
                                <SmallPostPreviewPictureImg
                                image={post.frontmatter.featuredimage.childImageSharp.small}
                                alt= {`featured image thumbnail for post ${post.frontmatter.title}`}
                                />
                            </SmallPostPreviewPicture>
                        </SmallPostPreviewLinkWrapper>
                        <SmallPostPreviewTextWrapper>
                            <SmallPostPreviewTextTitle>{post.frontmatter.tags[0]}</SmallPostPreviewTextTitle>
                            <SmallPostPreviewText>
                                <SmallPostPreviewLink to={`/${post.slug}`}>{post.frontmatter.title}</SmallPostPreviewLink>
                            </SmallPostPreviewText>
                        </SmallPostPreviewTextWrapper>
                    </SmallPostPreview4Col>
                ))}
            </PostPreview4Column>

            <PostPreview2ColumnLeft>
                {posts &&
                        posts.slice(9, 13).map(({ node: post }) => (
                        <SmallPostPreview 
                        key={post.id}>
                            <SmallPostPreviewLinkWrapper to={`/${post.slug}`}>
                                <SmallPostPreviewPicture
                                 as={motion.div}
                                 whileHover ={{
                                     scale : [1, 1.1],
                                     zIndex:  99,
                                     transition: {
                                         duration: .4
                                     }
                                     }}>
                                    <SmallPostPreviewPictureImg
                                    image={post.frontmatter.featuredimage.childImageSharp.small}
                                    alt= {`featured image thumbnail for post ${post.frontmatter.title}`}
                                    />
                                </SmallPostPreviewPicture>
                            </SmallPostPreviewLinkWrapper>
                            <SmallPostPreviewTextWrapper>
                                <SmallPostPreviewTextTitle>{post.frontmatter.tags[0]}</SmallPostPreviewTextTitle>
                                <SmallPostPreviewText>
                                    <SmallPostPreviewLink to={`/${post.slug}`}>{post.frontmatter.title}</SmallPostPreviewLink>
                                </SmallPostPreviewText>
                            </SmallPostPreviewTextWrapper>
                        </SmallPostPreview>
                ))}
            </PostPreview2ColumnLeft>

            {posts &&
                posts.slice(13, 14).map(({ node: post }) => (
                <LargePostPreview 
                key={post.id}>
                    <LargePostPreviewLinkWrapper to={`/${post.slug}`} >
                        <LargePostPreviewPicture
                         as={motion.div}
                         whileHover ={{
                             scale : [1, 1.1],
                             zIndex:  99,
                             transition: {
                                 duration: .4
                             }
                             }}>
                            <LargePostPreviewPictureImg
                                image={post.frontmatter.featuredimage.childImageSharp.large}
                                alt= {`featured image thumbnail for post ${post.frontmatter.title}`}
                                />
                        </LargePostPreviewPicture>
                    </LargePostPreviewLinkWrapper>
                
                    <LargePostPreviewTextWrapper>
                        <LargePostPreviewTextTitle>{post.frontmatter.tags[0]}</LargePostPreviewTextTitle>
                        <LargePostPreviewText>
                            <LargePostPreviewLink to={`/${post.slug}`}>{post.frontmatter.title}</LargePostPreviewLink>
                        </LargePostPreviewText>
                    </LargePostPreviewTextWrapper>
                    
                </LargePostPreview>
            ))}  
        </ArticleGrid>

        <AllArticlesLink to="/blog">All articles</AllArticlesLink>
    </InnerSectionWrapper>
    </SectionWrapper>
    </>
    )
  }


export default MostRecent