import React from 'react'
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
const SiteBannerSection = styled.div`

`

const SiteBannerWrapper = styled.div`
width: 94%;
margin-right: auto;
margin-left: auto;

@media screen and (min-width: 769px){
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding-bottom: 80px;
}

@media screen and (min-width: 769px){
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

@media screen and (max-width: 1920px){
max-width: 1330px;
}
`

const SiteBannerContent = styled.div`
@media screen and (min-width: 769px) {
width: 39.4791666667%;
}


@media screen and (max-width: 670px){
    margin-top: 50px;
}

@media screen and (max-width: 768px){
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
}

`

const SiteBannerTitle = styled.h1`
font-family: "Anton", "Impact", Impact;
font-weight: 400;
line-height: 120%;
text-transform: uppercase;
font-size: 4.5rem;
color: #303C3C;

@media screen and (min-width: 1025px) {
font-size: 4.5rem;
}

@media screen and (max-width: 1024px){
    font-size: 3.25rem;
}

@media screen and (max-width: 670px){
    font-size: 36px;
}



`

const SiteBannerTitleSpan = styled.span`
font-family: "Anton", "Impact", Impact;
font-weight: 400;
line-height: 120%;
text-transform: uppercase;
color: #C24C92;

@media screen and (max-width: 670px){
    margin-top: 20px;
}
`

const SiteBannerText = styled.div`
@media screen and (min-width: 1025px){
font-size: 1rem;
}

@media screen and (min-width: 671px){
    margin-top: 30px;
}

margin-top: 20px;
margin-bottom: 20px;
`

const SiteBannerTextP = styled.p`
line-height: 180%;
margin-top: 20px;
margin-bottom: 20px;
@media screen and (min-width: 1025px){
    font-size: 1rem;
    }
`
const SiteBannerSignup = styled.div`
@media screen and (min-width: 671px){
    margin-top: 30px;
}


`

const SiteBannerSignupForm = styled.div`

`

const SiteBannerSignupFormText = styled.p`
line-height: 180%;

@media screen and (min-width: 1025px){
    font-size: 1rem;
    }

`

const SiteBannerSignupFormInline = styled.form`
position: relative;
`

const SiteBannerSignupInput = styled.input`
box-sizing: border-box;
padding-left: 15px;
height: 50px;
font-size: 1rem;
width: 100%;
display: block;
padding-top: 3px;
background: none;
border: 1px solid #303C3C;
font-family: "Epilogue","Arial",sans-serif;
line-height: normal;
border-radius: 0;
-webkit-appearance: none;
transition: border-color ease .4s;


@media screen and (min-width: 671px){
    padding-right: 70px;
}
`

const SiteBannerSignupButton = styled.button`
font-family: "Anton", "Impact", Impact;
font-weight: 400;
text-transform: uppercase;
line-height: 120%;
display: inline-block;
cursor: pointer;
border: none;
text-align: center;
color: #fff1de;
border-radius: 0;
-webkit-appearance: none;
position: absolute;
top: 50%;
-webkit-transform: translateY(-50%);
transform: translateY(-50%);
padding: 0;
background: none;
width: 26px;
right: 20px;
margin-top: 0;

@media screen and (min-width: 671px){
font-size: 1.25rem;
}

`
const SiteBannerSignupSvg = styled.svg`

`
const SiteBannerImage = styled.div`
@media screen and (min-width: 769px){
    margin-left: auto;
}

@media screen and (min-width: 1025px){
    width: 48.125%;
}

@media screen and (max-width: 670px){
    margin-top: 50px;
}


@media screen and (max-width: 1024px) and (min-width: 769px){
    width: 48.125%;
    min-height: calc((50vw - 24.9375px) - 50px);
}

`

const SiteBannerImageInner = styled.div`
position: relative;


@media screen and (max-width: 1440px){
    width: 100%;
}



`

const SiteBannerImagePicture = styled.div`
max-width: 100%;
width:100%;
height: auto;
`

const SiteBannerImagePictureImg = styled(GatsbyImage)`
width: 100%;
max-height: 75vh;
-o-object-fit: cover;
object-fit: cover;
display: block;
max-width: 100%;
aspect-ratio: attr(width) / attr(height);
`



const Hero = ({data}) => {
    if (!data) return null;
    return (
    <>
    <SiteBannerSection>
	<SiteBannerWrapper>
		<SiteBannerContent>
			<SiteBannerTitle>
                <SiteBannerTitleSpan>Find crystals</SiteBannerTitleSpan>
                <br/>
                so cute, you'll make a fairy jealous.
            </SiteBannerTitle>
			<SiteBannerText>
				<SiteBannerTextP>You don't have to find a pickaxe and go digging in a cave to get quality crystals. Just subscribe and get our free alerts - flash sales on raw crystals and jewelry.
                </SiteBannerTextP>
				<SiteBannerSignup>
					<SiteBannerSignupForm>
						<SiteBannerSignupFormText></SiteBannerSignupFormText>
						<SiteBannerSignupFormInline method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
							<SiteBannerSignupInput type="email" name="EMAIL" placeholder="Enter email…" autocomplete="off" aria-label="Enter email address"/>
							<input type="hidden" name="bot-field" />
							<input type="hidden" name="form-name" value="contact" />
                            <SiteBannerSignupButton aria-label="submit">
								<SiteBannerSignupSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.41 19.41">
									<g id="fbae9a04-7ba6-4a52-9d53-b676ffa219dd" data-name="Layer 2">
										<polygon id="b6d30a43-977e-49d8-b2e0-7b8e92337b96" points="20.71 0 19.29 1.41 26.59 8.71 0 8.71 0 10.71 26.59 10.71 19.29 18 20.71 19.41 30.41 9.71 20.71 0" class="e3614bc1-a667-4a77-8ad5-3967e867c60b" data-name="Layer 1"></polygon>
									</g>
								</SiteBannerSignupSvg>
							</SiteBannerSignupButton>
						</SiteBannerSignupFormInline>
					</SiteBannerSignupForm>
				</SiteBannerSignup>
			</SiteBannerText>
		</SiteBannerContent>
		<SiteBannerImage>
			<SiteBannerImageInner>
                <SiteBannerImagePicture>
                    <SiteBannerImagePictureImg
                        image={data.frontmatter.heroImage.childImageSharp.gatsbyImageData}
                        alt= {`featured image thumbnail for post ${data.frontmatter.title}`}
                        loading="eager"
                    >
                    </SiteBannerImagePictureImg>
                </SiteBannerImagePicture>
			</SiteBannerImageInner>
        </SiteBannerImage>
    </SiteBannerWrapper>
    </SiteBannerSection>
    </>
    )
}
    

export default Hero
